<template>
  <div>
    <div class="uk-flex uk-flex-between uk-flex-middle">
      <div class="uk-text-large">Equipos de venta</div>
      <div>
        <button
          class="uk-button uk-button-primary uk-border-rounded"
          href="#edit-saleTeam-modal"
          uk-toggle
          @click="setNewMode"
        >
          Crear nuevo equipo de venta
        </button>
      </div>
    </div>

    <table
      class="uk-table uk-table-striped uk-table-middle uk-table-hover uk-table-divider"
    >
      <thead>
        <tr>
          <th width="25%">Nombre</th>
          <th width="25%">Vendedores</th>
          <th width="25%">País</th>
          <th width="25%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="saleTeam in saleTeams" :key="saleTeam.id">
          <td>{{ saleTeam.name }}</td>
          <td>
            <div v-for="user in saleTeam.users" :key="user.id">
              {{ user.first_name + " " + user.last_name }}
            </div>
          </td>
          <td>{{ saleTeam.country.spanish }}</td>
          <td>
            <div class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <router-link
                  class="uk-button uk-button-default"
                  uk-icon="expand"
                  :to="'/sale-teams/' + saleTeam.id.toString()"
                ></router-link>
                <button
                  class="uk-button uk-button-default"
                  uk-icon="pencil"
                  href="#edit-saleTeam-modal"
                  uk-toggle
                  @click="setEditMode(saleTeam)"
                ></button>
                <button
                  class="uk-button uk-button-default"
                  uk-icon="trash"
                  href="#delete-saleTeam-modal"
                  uk-toggle
                  @click="setEditMode(saleTeam)"
                ></button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      id="edit-saleTeam-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          equipo de venta
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Nombre</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="name"
              v-model="model.name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="country" rules="" v-slot="{ errors }">
            <div class="uk-margin-top">País</div>
            <v-select
              id="country-select"
              v-model="model.country_id"
              class="uk-input uk-border-rounded"
              :options="countriesCountries"
              :reduce="(country) => country.id"
              :getOptionLabel="(option) => option.spanish"
            ></v-select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>

          <ValidationProvider
            name="type"
            rules="required"
            v-slot="{ errors }"
            v-if="model.salesmen"
          >
            <div class="uk-margin-top">Vendedores</div>
            <div v-for="salesman in payload.salesmen" :key="salesman.id">
              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  v-model="model.salesmen[salesman.id].selected"
                />
                {{ salesman.first_name }}
                {{ salesman.last_name }}
              </label>
            </div>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading"
              @click="mode === 'new' ? store() : update()"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else>
                <span v-if="mode === 'new'">Crear</span>
                <span v-else>Editar</span>
                equipo de venta
              </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div
      id="delete-saleTeam-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
        v-if="selectedUser"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          ¿Está seguro que desea eliminar {{ selectedUser.name }}?
        </div>

        <div class="uk-text-center uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-border-rounded"
            @click="destroy"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UIkit from "uikit";

const blankModel = {
  name: null,
  salesmen: null,
  country_id: null,
};

export default {
  name: "SaleTeamsIndex",

  // props: ["payload"],

  data() {
    return {
      mode: "new",
      model: this._.cloneDeep(blankModel),
      saleTeams: null,
      salesmen: null,
      selectedUser: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters(["countriesCountries", "countriesIsLoadingCountries"]),
  },

  mounted() {
    this.countriesFetchCountries();
    this.fetchSaleteams();
  },

  methods: {
    ...mapActions(["countriesFetchCountries"]),
    resetModel() {
      this.model = this._.cloneDeep(blankModel);
      this.model.salesmen = this.salesmen.reduce((accum, salesman) => {
        accum[salesman.id] = {
          ...salesman,
          selected: false,
        };

        return accum;
      }, {});
    },
    fetchSaleteams() {
      this.isLoading = true;
      this.axios
        .get("sale-teams")
        .then(({ data: { saleTeams, salesmen } }) => {
          this.saleTeams = saleTeams;
          this.salesmen = salesmen;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setNewMode() {
      this.resetModel();
      this.mode = "new";
      this.selectedUser = null;
    },
    setEditMode(saleTeam) {
      this.mode = "edit";
      this.selectedUser = saleTeam;
      this.model.name = saleTeam.name;
      this.model.country_id = saleTeam.country_id;
      const saleTeamUserIds = saleTeam.users.map((user) => user.id);
      this.model.salesmen = this.payload.salesmen.reduce((accum, salesman) => {
        accum[salesman.id] = {
          ...salesman,
          selected: saleTeamUserIds.includes(salesman.id),
        };

        return accum;
      }, {});
    },
    store() {
      this.isLoading = true;
      this.axios
        .post("/sale-teams", {
          ...this.model,
          salesmen: Object.keys(this.model.salesmen).filter(
            (salesmanId) => this.model.salesmen[salesmanId].selected
          ),
        })
        .then(() => {
          this.fetchSaleteams();
          UIkit.modal("#edit-saleTeam-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      this.axios
        .put("/sale-teams/" + this.selectedUser.id.toString(), {
          ...this.model,
          salesmen: Object.keys(this.model.salesmen).filter(
            (salesmanId) => this.model.salesmen[salesmanId].selected
          ),
        })
        .then(() => {
          this.fetchSaleteams();
          UIkit.modal("#edit-saleTeam-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    destroy() {
      this.isLoading = true;
      this.axios
        .delete("/sale-teams/" + this.selectedUser.id.toString())
        .then(() => {
          this.fetchSaleteams();
          UIkit.modal("#delete-saleTeam-modal").hide();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
